

@font-face {
    font-family: 'Roboto';
    src: url('../components/font/Roboto/Roboto-Regular.ttf') format('truetype');
}



@font-face {
    font-family: 'Nunito';
    src: url('../components/font/Nunito/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
}



@font-face {
    font-family: 'Noto_Sans_KR';
    src: url('../components/font/Noto_Sans_KR/NotoSansKR-VariableFont_wght.ttf') format('opentype');
}




@font-face {
    font-family: 'Lato';
    src: url('../components/font/Lato/Lato-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Noto_Sans_JP';
    src: url('../components/font/Noto_Sans_JP/NotoSansJP-VariableFont_wght.ttf') format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    src: url('../components/font/Montserrat/Montserrat-VariableFont_wght.ttf') format('opentype');
}

@font-face {
    font-family: 'Open_Sans';
    src: url('../components/font/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('opentype');
}

@font-face {
    font-family: 'Lora';
    src: url('../components/font/Lora/Lora-VariableFont_wght.ttf') format('opentype');
}

@font-face {
    font-family: 'Noto_Sans_SC';
    src: url('../components/font/Noto_Sans_SC/NotoSansSC-VariableFont_wght.ttf') format('opentype');
}






.container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;

}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    cursor: pointer;
}