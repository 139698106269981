.valentine_container {
  width: 100%;
  height: 82vh;
  margin-top: 3vh;
  padding-bottom: 5vh;
  background-size: cover;
  background-color: #000000;
  background-blend-mode: color;

  .text_wrap{
    opacity: 1;
  }
  .wrap {
    height: 100%;
    overflow: hidden;
   
  }

  .text {
    color: white;
    padding: 10px;
    font-weight: 500;
    opacity: 0; // Initial opacity set to 0
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centering transformation */
    text-align: center;
    color: white;
    animation: blinking-text-title 3s infinite !important;
    opacity: 0; // Initial opacity set to 0
  }

  @keyframes blinking-text-title {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1); /* Centering transformation */
    }
    50% {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1.2); /* Centering transformation */
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1); /* Centering transformation */
    }
  }

  .custom_btn {
    background: transparent;
    z-index: 10;
    position: absolute;
    top: 3%;
    display: flex;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    align-items: center;
    justify-content: center;
    right: 3%;
    border: none;
    opacity: 0;

    img{
        width: 90%;
    }
  }

  .fade-in {
    opacity: 1 !important; // Ensure that opacity transitions to the final value
  }

  .final-animation {
    animation: blinking-text 3s infinite;
  }

  .enlarged {
    transform: scale(1.2);
  }

  .fade-in_button {
    transition: 2s;
    opacity: 1;
  }

  @keyframes blinking-text {
    0% {
      opacity: 1;
      scale: 1;
    }
    50% {
      opacity: 0.5;
      scale: 1.2;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

  @media screen and (min-width: 768px) {
    .wrap{
      width: 90%;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      height: 100vh;
      background-color: transparent;
      left: 55%;
      transform: translateX(-50%);
    }
    .valentine_container {
      width: 100%;
      overflow: hidden;

     

      .centered {
        left: 45%;
        animation: blinking-text-pc 3s infinite !important;
      }

      .text {
        color: white;
        padding: 0px;
        font-weight: 500;
        font-size: 180% !important;
        animation: blinking-text-pc 3s infinite !important;
        opacity: 1;
        &:hover {
          transition: 0.5s;
          opacity: 0 !important;
        }
      }
    }

    @keyframes blinking-text-pc {
      0% {
        opacity: 1;
        scale: 1;
      }
      50% {
        opacity: 0.5;
        scale: 1.2;
      }
      100% {
        opacity: 1;
        scale: 1;
      }
    }
  }
}
